
import { Component, Vue } from 'vue-property-decorator';
import LatestDataCard from '@/components/LatestDataCard.vue';
import { dispatchGetStations } from '@/store/stations/actions';
import { readStations } from '@/store/stations/getters';
import { dispatchGetLatestData, dispatchUpdateLatestData } from '@/store/overviews/actions';
import { getLatestData } from '@/store/overviews/getters';
import { apiWSUrl } from '@/env';
import { getLocalToken } from '@/utils';
import { readUserRoles } from '@/store/main/getters';
import store from '@/store';
import { ICurrentData, ITideData, IWaveData, IWeatherData, IWindData } from '@/interfaces';

const connect = (ctx) => {

  const ws = new WebSocket(`${apiWSUrl}/api/v1/data/ws?token=${ctx.token}`);

  ws.onmessage = async (event) => {
    const latestData = ctx.latestData
    const receivedMessage: IWindData | IWeatherData | ICurrentData | IWaveData | ITideData = JSON.parse(event.data)
    const eqpId = Object.values(receivedMessage)[0].equipment_id
    latestData[eqpId] = receivedMessage
    await dispatchUpdateLatestData(ctx.$store, latestData);
  };

  ws.onclose = (e) => {
    setTimeout(() => connect(ctx), 1000);
  };

  ws.onerror = (e) => {
    ws.close();
  };

  return ws;
};


@Component({
  components: {
    LatestDataCard,
  },
})
export default class T2Overview extends Vue {
  public tab = null;
  private connection;

  get tabs() {
    const lot: string[] = [];
    const roles = ['T1', 'T2', 'VTS'];
    roles.forEach((role) => {
      if ([role, 'ADMIN'].some((r) => readUserRoles(store)?.includes(r))) {
        lot.push(role);
      }
    });
    return lot;
  }

  get stations() {
    const stations = readStations(this.$store).filter((el) => el.terminal === 'T2');
    stations.sort((a, b) => (a.location > b.location ? 1 : a.location < b.location ? -1 : 0));
    return stations;
  }

  get latestData() {
    return getLatestData(this.$store);
  }

  get token() {
    return getLocalToken();
  }

  public async mounted() {
    await dispatchGetStations(this.$store);
    await dispatchGetLatestData(this.$store);
  }

  public async created() {
    const ctx = this;
    this.connection = connect(ctx);
  }

  public async beforeRouteLeave(to, from, next) {
    this.connection.close();
    next();
  }
}
